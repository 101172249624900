import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import { User } from './shared/models/user.models';
import { NavbarService } from './core/components/navbar/navbar.service';
import { ThemeService } from './shared/service/theme.service';
import {
  Actions,
  ofActionDispatched,
  Select,
  Store,
} from '@ngxs/store';
import { GetUserById, Logout } from './store/auth/auth.actions';
import { Router } from '@angular/router';
import { AuthState } from './store/auth/auth.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthStateModel } from './store/auth/auth.model';
import { TranslateService } from '@ngx-translate/core';
import {
  SiteConfigObj,
  SiteConfigService,
} from './shared/service/site-config-service';
import { featureFlag } from '../environments/environment';
import { finalize, tap } from 'rxjs/operators';
import { ThemeInfo } from './modules/setting/component/theme/shared/theme.models';
import { setTheme } from 'ngx-bootstrap/utils';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AlertService } from './core/services/alert.service';
import { TokenService } from './core/authentication/token.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { QuestionModalService } from './modules/memos/components/upload-memos/questions/question-modal/question-modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: User;
  navbarActive = false;
  defaultLang = 'en';
  loadingSuccess$ = new BehaviorSubject<boolean>(false);
  countdown?: number = null;
  sessionTimeOut = null;
  toggleCustomModal = true;

  @ViewChild('sessionTimoutModal', { static: true })
  sessionTimoutModal: ElementRef;
  modal: NgbModalRef;

  @Select(AuthState.token) userList$: Observable<AuthStateModel>;

  constructor(
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private navbar: NavbarService,
    private themeService: ThemeService,
    private siteConfigService: SiteConfigService,
    private actions: Actions,
    private store: Store,
    private translateService: TranslateService,
    private idle: Idle,
    private keepalive: Keepalive,
    private alertService: AlertService,
    private tokenService: TokenService,
    private modalService: NgbModal,
    private titleService: Title,
    private zone: NgZone,
    public questionModalService: QuestionModalService,
  ) {
    setTheme('bs4');
    this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigate(['/login']);
    });
    this.initializeLanguage();

    // make sure that site setting is loaded before showing website.
    this.checkSiteConfig().then(() => {
      this.getTheme().subscribe(() => {
        this.navbar.data.subscribe((res) => {
          this.navbarActive = res;
        });
      });
    });
  }

  reset(): void {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.countdown = null;
  }

  ngOnInit(): void {
    if (
      !this.store.selectSnapshot<string>(
        (state) => state.auth.access,
      ) &&
      this.navbarActive
    ) {
      this.store.dispatch(GetUserById);
    }
    // right when the component initializes, start reset state and start watching
    this.setIdle().then(() => {
      this.reset();
      this.refreshToken();
    });
  }

  getSessionPolicy(): Promise<void> {
    return new Promise((resolve) => {
      this.siteConfigService
        .getSessionPolicy()
        .subscribe((config: any) => {
          this.sessionTimeOut = config?.session_timeout;
          resolve();
        });
    });
  }

  async setIdle() {
    await this.getSessionPolicy();

    if (!this.sessionTimeOut && featureFlag.ndid) {
      this.sessionTimeOut = 60;
    }

    this.idle.setIdle(60 * (this.sessionTimeOut || 60));
    this.idle.setTimeout(15);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => {
      if (!this.sessionTimeOut) {
        this.reset();
        return;
      }
      if (this.router.url.includes('login')) {
        this.reset();
        return;
      }

      this.modal = this.modalService.open(this.sessionTimoutModal, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
    });
    this.idle.onTimeout.subscribe(() => {
      this.logout();
    });
    this.idle.onTimeoutWarning.subscribe((seconds) => {
      this.countdown = seconds;
    });
    this.keepalive.interval(4 * 60);
    this.keepalive.onPing.subscribe(() => this.refreshToken());
  }

  /**
   * Set the initial language in localStorage
   * that will be used to stuff in HTTP Headers - `Accept-Language`
   * and for translation.
   */
  initializeLanguage(): void {
    this.defaultLang = localStorage.getItem('lang');
    if (!this.defaultLang || this.defaultLang === 'null') {
      localStorage.setItem('lang', 'en');
      this.translateService.setDefaultLang('en');
    }
    this.defaultLang = localStorage.getItem('lang');
    this.translateService.use(this.defaultLang);
  }

  getTheme(): Observable<ThemeInfo> {
    this.loadingSuccess$.next(false);
    this.spinnerService.show();
    return this.themeService.getTheme().pipe(
      tap((theme) => {
        this.themeService.setThemeList(theme);
      }),
      finalize(() => {
        this.loadingSuccess$.next(true);
        this.spinnerService.hide();
      }),
    );
  }

  checkSiteConfig(): Promise<void> {
    return new Promise((resolve) => {
      this.titleService.setTitle(featureFlag.page_title);

      if (
        featureFlag.maintenance_mode === true &&
        !this.router.url.startsWith('/website-maintenance')
      ) {
        // force redirect to maintenance page when website is in maintenance mode.
        this.zone.run(() => {
          this.router.navigate(['/website-maintenance']).then(() => {
            resolve();
          });
        });
      } else if (
        featureFlag.maintenance_mode !== true &&
        this.router.url.startsWith('/website-maintenance')
      ) {
        // force redirect to login mode when website is not in maintenance.
        this.zone.run(() => {
          this.router.navigate(['/login']).then(() => {
            resolve();
          });
        });
      } else {
        resolve();
      }
    });
  }

  refreshToken(): void {
    if (this.router.url.includes('login')) {
      return;
    }

    const token = this.tokenService.getRefreshToken();
    if (token) {
      this.tokenService.refreshToken(token).subscribe((tk: any) => {
        this.tokenService.saveToken(tk.access);
      });
    }
  }

  logout(): void {
    this.dismissAll();
    this.reset();
    this.store.dispatch(Logout);
  }

  dismissAll(): void {
    this.modalService.dismissAll();
  }
}
