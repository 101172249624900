import { LoaderState } from './store/loader/loader.state';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './core/components/home/home.component';
import { LoginComponent } from './core/components/login/login.component';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbDatepickerModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { NgxsModule } from '@ngxs/store';
import { UserState } from './store/users/users.state';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdfsCallbackComponent } from './core/components/adfs-callback/adfs-callback.component';
import { AuthState } from './store/auth/auth.state';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { MemoCreationState } from './store/memo/memo.state';
import { OtpState } from './store/otp/otp.state';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NdidState } from './store/ndid/ndid.state';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
import { NgbDatepickerI18nBuddhist } from './datepicker-i18n';
import {
  ExtendedNgbDateAdapter,
  ExtendedNgbDateParserFormatter,
} from './dateparser';
import { ExtendedNgbDatepickerConfig } from './datepicker-config';
import { BuddhistDatePipe } from './shared/pipes/date.pipe';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import localeThai from '@angular/common/locales/th';
import { QuestionsModule } from './modules/memos/components/upload-memos/questions/questions.module';
import { GlobalErrorHandler } from './shared/service/error-handler';
import { environment } from 'src/environments/environment';
import { DepartmentState } from './store/department/department.state';

registerLocaleData(localeThai);

export const createTranslateLoader = (
  http: HttpClient,
): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdfsCallbackComponent,
    HomeComponent,
    PageNotFoundComponent,
    MaintenanceComponent,
    SidebarComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    SharedModule,
    NgbModule,
    TreeModule,
    NgbDatepickerModule,
    NgxsModule.forRoot(
      [
        UserState,
        AuthState,
        MemoCreationState,
        LoaderState,
        OtpState,
        NdidState,
        DepartmentState,
      ],
      {
        developmentMode: false,
      },
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      isolate: false,
    }),
    BsDatepickerModule.forRoot(),
    NgxCaptchaModule,
    NgIdleKeepaliveModule.forRoot(),
    QuestionsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: NgbDatepickerI18n,
      useClass: NgbDatepickerI18nBuddhist,
    },
    {
      provide: NgbDatepickerConfig,
      useClass: ExtendedNgbDatepickerConfig,
    },
    { provide: NgbDateAdapter, useClass: ExtendedNgbDateAdapter },
    {
      provide: NgbDateParserFormatter,
      useClass: ExtendedNgbDateParserFormatter,
    },
    BuddhistDatePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
