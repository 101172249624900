import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  imageChangedEvent: any = '';
  modal: NgbModalRef;
  croppedImage: any = '';
  fileInput;
  blobOutput;
  errorMsg: any = '';
  currentImage: any;
  image: any;
  @Input() imageFile: FormControl;
  /** Specify the style for the file selection button */
  @Input() presetStyle: PresetName = 'modern';
  @Output() imageFileChange = new EventEmitter();
  @Output() imagePreview = new EventEmitter();
  constructor(private modalService: NgbModal) {}

  imageLoaded(): void {
    // show cropperx
  }

  openUploadSign(cropImg: any): void {
    this.blobOutput = '';
    this.modal = this.modalService.open(cropImg, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
  }

  imageCropped(output: any): void {
    this.croppedImage = output.base64;
    this.blobOutput = this.base64ToFile(
      output.base64,
      this.imageChangedEvent.target?.files[0]?.name,
    );
  }

  base64ToFile(data: any, filename: string): File {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.fileInput = file.target.files[0];
  }

  loadImageFailed(): void {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg =
      'ไม่สามารถประมวลผลภาพได้ กรุณาอัปโหลดภาพใหม่อีกครั้ง';
    // show message
  }

  saveSignature(): void {
    if (this.fileInput && this.blobOutput) {
      const filename =
        this.fileInput.name.substring(
          0,
          this.fileInput.name.lastIndexOf('.'),
        ) + '.png';
      this.image = new File([this.blobOutput], filename, {
        type: this.blobOutput.type,
      });
      this.imageFile.setValue(this.image);
      this.imagePreview.emit(this.image);
      // this.updateSignature();
    }
    this.modal.dismiss();
  }
}

export type PresetName = 'modern' | 'hypertext';
