<button class="btn btn-select"
    [ngClass]="[presetStyle]"
    (click)="openUploadSign(cropImg)">
  {{'CONFIGURATION.CHOOSE-IMAGE' | translate}}
</button>

<ng-template #cropImg let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <div class="col">
      <!--title-->
    </div>
    <div class="col-auto">
      <i class="fas fa-times pointer size-24px" data-dismiss="modal" (click)="d('Cross click')"></i>
    </div>
  </div>
  <div class="modal-body">
    <div [ngClass]="{'d-none': !currentImage || imageChangedEvent}">
      <div class="row justify-content-center">
        <div class="col-4 align-self-center text-center bg-white">
          <img class="img-fluid img-thumbnail blend-hard-light" [src]="currentImage">
          <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.CURRENT-SIGNATURE"|translate}}</h6>
        </div>
      </div>
    </div>
    <div class="row" [ngClass]="{'d-none': currentImage && !imageChangedEvent}">
      <div class="col-6 align-self-center text-center">
        <img class="img-fluid img-thumbnail" *ngIf="!imageChangedEvent"
             src="assets/images/original.png">
        <image-cropper *ngIf="imageChangedEvent" [imageChangedEvent]="imageChangedEvent"
                       [maintainAspectRatio]="false" (imageCropped)="imageCropped($event)"
                       (imageLoaded)="imageLoaded()"[aspectRatio]="16 / 9"
                       (loadImageFailed)="loadImageFailed()" format="png" outputType="both"
                       class="img-thumbnail"
                       #imageCropper>
        </image-cropper>
        <input type="file" accept="image/*" class="d-none" (change)="fileChangeEvent($event)" #fileSignature>
        <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.ORIGINAL-IMAGE"|translate}}</h6>
      </div>
      <div class="col-6 align-self-center text-center">
        <img class="img-fluid img-thumbnail" src="assets/images/preview.png"
             *ngIf="!croppedImage">
        <div class="img-thumbnail" *ngIf="croppedImage">
          <div class="overflow-hidden h-100pc">
            <div class="d-inline-block align-middle text-center h-100pc bg-white">
              <span class="d-inline-block align-middle h-100pc m-t-1"></span>
              <img class="img-fluid align-middle mh-100pc outline-grey blend-hard-light" [src]="croppedImage">
            </div>
          </div>
        </div>
        <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.SAMPLE-IMAGE"|translate}}</h6>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="position: relative">
    <div class="message-box"><span class="message-error">{{ errorMsg }}</span></div>
    <button type="button" class="btn btn-outline-secondary"
            (click)="fileSignature.click()">{{"CREATE-USER.UPLOAD"|translate}}</button>
    <!-- <button type="button" class="btn btn-warning" (click)="clearSignature()">คืนค่า</button> -->
    <button type="button" class="btn btn-success" data-dismiss="modal"
            (click)="saveSignature()">{{"CREATE-USER.SAVE"|translate}}</button>
  </div>
</ng-template>
